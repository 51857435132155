// ==================================================
//  Event: モバイル判定
// ==================================================
let vw = window.innerWidth;
const breakpointSP = 767;
const isMobile = (vw) => {
  if (breakpointSP >= vw) {
    return true;
  } else {
    return false;
  }
}


// ==================================================
//  Event: スクロールイベント
// ==================================================
const header = document.querySelector("header");
let scrollTicking = false;
document.addEventListener('scroll', (event) => {

  if (!scrollTicking) {
    requestAnimationFrame(() => {
      scrollTicking = false;

      // 追従ヘッダーの横スクロール対応
      header.style.left = -window.scrollX + 'px';
    });
    scrollTicking = true;
  }
  },
  { passive: true }

);


// ==================================================
//  Event: ヘッダーメニュー
// ==================================================
const headerMenuButton = document.querySelector(".js_headerMenuButton");
const headerMenu = document.querySelector(".js_headerMenu");
const headerMenuSubSpMenuButton = document.querySelector(".header_menuSub_spMenuButton");
const headerMenuSpMenuButtonClose = document.querySelector(".header_menu_spMenuButtonClose");
const navigationLink = document.querySelectorAll(".header_menu_navigation_list_item_inner");
function headerMenuEvent () {
  headerMenuButton.classList.toggle('js_headerMenuButton-active');
  headerMenu.classList.toggle('js_headerMenu-active');
}
headerMenuButton.addEventListener('click', function () {
  headerMenuEvent();
});
headerMenuSpMenuButtonClose.addEventListener('click', function () {
  headerMenuEvent();
});
headerMenuSubSpMenuButton.addEventListener('click', function () {
  headerMenuEvent();
});
// ナビゲーションのリンクをクリックしてナビゲーションを閉じる
const uri = new URL(window.location.href);
const uriOrigin = uri.origin;
for (let i = 0; i < navigationLink.length; i++) {
  navigationLink[i].addEventListener('click', (e) => {
    let href = navigationLink[i].getAttribute('href');
    if (href.indexOf(uriOrigin) == -1) {
      headerMenuEvent();
    }
  });
}